import BigNumber from 'bignumber.js/bignumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const POLYGON_BLOCK_TIME = 2
export const PWINGS_PER_BLOCK = new BigNumber(2)
export const DESPAIR_PER_BLOCK = new BigNumber(0.525185185185185)
export const BLOCKS_PER_YEAR = new BigNumber((60 / POLYGON_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const VAULTS_DISTRIBUTION_PERCENTAGE = 0.94
export const BASE_URL = 'https://polygon.jetswap.finance'
export const BASE_EXCHANGE_URL = 'https://polygon-exchange.jetswap.finance/'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50
export const LOTTERY_TICKET_PRICE = 1
export const START_BLOCK_NUMBER = 16645000
