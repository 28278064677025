import { Ifo } from './types'

const ifos: Ifo[] = [
  {
    id: 'naut',
    address: '0xbA27508244D09347ed8F41769aBa6Fe71d703EAc',
    isActive: true,
    name: 'Astronaut Polygon Token',
    subTitle: 'Launchpad and incubator decentralizing the way projects and ideas raise capital',
    description:
      "Astronaut allows decentralized projects to raise and exchange capital cheap and fast. Users will be able to participate in a secure and interoperable environment. With multiple different utilities such as NFT's, IDO allocation and whitelisting the experience is smooth and simple. Astronaut has a team that with a very dynamic background in development, marketing and operations. We launch projects to the moon!",
    launchDate: 'Oct. 6',
    launchTime: '5 PM UTC',
    saleAmount: '4,000,000 pNAUT',
    raiseAmount: '$500,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://astronaut.to/',
    currency: 'USDC',
    currencyAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    currencyDecimals: 6,
    tokenDecimals: 18,
    tokenSymbol: 'pNAUT',
    releaseBlockNumber: 19932000,
    campaignId: '511080000',
    maxDepositAmount: 2000,
  },
  {
    id: 'avto',
    address: '0x4928d3cB054E0de081e7eD702C2e728E74e058be',
    isActive: false,
    name: 'AVTO',
    subTitle:
      'avtoMATIC is a Polygon blockchain based yield aggregator / optimizer that seeks to provide long term investors with long term returns within single pane of glass ecosystem.',
    description:
      'Revolutionary decentralized yield generation crypto fund powered by a unique yield aggregation platform on Polygon. With a focus on security, innovation, and ethical non-adversarial sustainability, avtoMATIC seeks to pave the way as a gold standard for the Polygon ecosystem and for DEFI protocols across the space.',
    launchDate: 'Sep. 23',
    launchTime: '5 PM UTC',
    saleAmount: '2,100,000 AVTO',
    raiseAmount: '$500,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://avtomatic.finance/',
    currency: 'USDC',
    currencyAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    currencyDecimals: 6,
    tokenDecimals: 18,
    tokenSymbol: 'AVTO',
    releaseBlockNumber: 19456600,
    campaignId: '511080000',
    maxDepositAmount: 15000,
  },
  {
    id: 'pforce',
    address: '0xA34986279a54Fb8F9371C1F7BD5867035402Dc24',
    isActive: false,
    name: 'PFORCE',
    subTitle: 'A deflationary passive yield and automatic liquidity generating token backed by the jet fuel ecosystem',
    description:
      'PFORCE is an innovative token in the Jetfuel.Finance ecosystem that is revolutionizing the passive yield and staking token landscape.',
    launchDate: 'July. 15',
    launchTime: '2 PM GMT',
    saleAmount: '500,000 PFORCE',
    raiseAmount: '$500,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: '/pforce',
    currency: 'USDC',
    currencyAddress: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    currencyDecimals: 6,
    tokenDecimals: 9,
    tokenSymbol: 'PFORCE',
    releaseBlockNumber: 8127180,
    campaignId: '511080000',
    maxDepositAmount: 5000,
  },
]

export default ifos
