import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.pwings,
    earningToken: tokens.pwings,
    contractAddress: {
      80001: '',
      137: '0x4e22399070aD5aD7f7BEb7d3A7b543e8EcBf1d85',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 19,
    stakingToken: tokens.pforce,
    earningToken: tokens.pwings,
    contractAddress: {
      80001: '',
      137: '0x4e22399070aD5aD7f7BEb7d3A7b543e8EcBf1d85',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 100,
    stakingToken: tokens.pwings,
    earningToken: tokens.pforce,
    contractAddress: {
      80001: '',
      137: '0xFD919978845247e147364F0A1f1565AAC4Fcd472',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.009',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 102,
    stakingToken: tokens.pwings,
    earningToken: tokens.pswamp,
    contractAddress: {
      80001: '',
      137: '0xaF15987C889296f6F597AfFd382f695e88e83666',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000625',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 101,
    stakingToken: tokens.pwings,
    earningToken: tokens.pforce,
    contractAddress: {
      80001: '',
      137: '0xeC4b9185E61101cEb91fA70E99419d4BEB57130C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.009',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 103,
    stakingToken: tokens.pwings,
    earningToken: tokens.honor,
    contractAddress: {
      80001: '',
      137: '0x5203aD80B3917A265A1E9FA88ED4772F5c7629c3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.625',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 104,
    stakingToken: tokens.pwings,
    earningToken: tokens.crystl,
    contractAddress: {
      80001: '',
      137: '0xbA0C76d19208D633175Dd901eA509b33811f9c30',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.027',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 105,
    stakingToken: tokens.pwings,
    earningToken: tokens.piratep,
    contractAddress: {
      80001: '',
      137: '0x4F1479c05FF5b321eFAB5a2f7bB4F61c07402E57',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.11',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 106,
    stakingToken: tokens.pwings,
    earningToken: tokens.time,
    contractAddress: {
      80001: '',
      137: '0x0CF2572f50107980352eA3174cc27590aC96BD0D',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00156',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 107,
    stakingToken: tokens.pwings,
    earningToken: tokens.honor,
    contractAddress: {
      80001: '',
      137: '0xb3B3bfa4047BCeA2E95209541E9a1604F17536C0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 108,
    stakingToken: tokens.pwings,
    earningToken: tokens.pear,
    contractAddress: {
      80001: '',
      137: '0x98A821bF82ADC32d96114f505B4494b1362EF5Ae',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0375',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 109,
    stakingToken: tokens.pwings,
    earningToken: tokens.time,
    contractAddress: {
      80001: '',
      137: '0x9b62a8d9C7B94F87eC84e3eBd164356229151e31',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00195',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 110,
    stakingToken: tokens.pwings,
    earningToken: tokens.alpha,
    contractAddress: {
      80001: '',
      137: '0x932FA17c990E690F87CcBB33F4afe5a7D9FcB836',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00003',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 111,
    stakingToken: tokens.pwings,
    earningToken: tokens.avto,
    contractAddress: {
      80001: '',
      137: '0xaa103f0Bb0bE40f8eAF599A8154FFE3d058E9ec2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.05',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 112,
    stakingToken: tokens.pwings,
    earningToken: tokens.time,
    contractAddress: {
      80001: '',
      137: '0xC7c62d113317AcB3385299d9b094C06FC4d571BE',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00236',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 113,
    stakingToken: tokens.pwings,
    earningToken: tokens.collar,
    contractAddress: {
      80001: '',
      137: '0x5A163eC904E66dBEE06300A3f12504e948a3B8b6',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0005',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 114,
    stakingToken: tokens.pwings,
    earningToken: tokens.pnaut,
    contractAddress: {
      80001: '',
      137: '0xf43E460F5E3fee81A6fD7da520e11202A117294B',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.05',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 115,
    stakingToken: tokens.pforce,
    earningToken: tokens.pbrew,
    contractAddress: {
      80001: '',
      137: '0xB7b9D702f7354A48eF1dA314841569d1d2881424',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.08',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 116,
    stakingToken: tokens.pwings,
    earningToken: tokens.avto,
    contractAddress: {
      80001: '',
      137: '0x7b9Ec0d578230f252F332B8B2c632f4467f7c2B8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0315',
    sortOrder: 1,
    isFinished: false,
  },
]

export default pools
